import React, { createContext, useState } from 'react';
import { API_URL, CONSTANT } from '../Utils/Constant';

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const [footerBlogData, setFooterBlogData] = useState([]);
  const [bottomSliderBlogs, setBottomSliderBlogs] = useState([]);
  const [ipAddress, setIpAddress] = useState('');
  const [deviceInfo, setDeviceInfo] = useState('');

  const fetchBlogList = async (blogId) => {
    try {
      const url = `${CONSTANT.BACKEND_API_URL}${API_URL.BLOGS}&pagination[start]=0&pagination[limit]=${blogId ? 6 : 2}&sort=updatedAt:desc`;

      const response = await fetch(url).then((response) => response.json());
      if (response.data && response.data.length > 0) {
        if (blogId) {
          const tempArray = response?.data
            ?.filter((x) => x?.id !== blogId)
            ?.map((value) => ({
              title: value?.attributes?.title,
              slug: value?.attributes?.slug,
              description: value?.attributes?.shortDescription,
              date: value?.attributes?.updatedAt,
              img: CONSTANT?.BACKEND_API_URL + value?.attributes?.bannerImage?.data?.attributes?.url
            }));
          setBottomSliderBlogs([...tempArray]);
        } else {
          const tempArray = response?.data?.map((value) => ({
            title: value?.attributes?.title,
            slug: value?.attributes?.slug
          }));
          setFooterBlogData(tempArray);
        }
      }
    } catch (error) {
      console.error('ERROR : Blog List Not Found!');
    }
  };

  const fetchUserIPv4 = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      setIpAddress(data.ip);
    } catch (error) {
      console.error('Error getting IPv4:', error);
    }
  };

  const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    setDeviceInfo(userAgent);
  };

  return (
    <DataContext.Provider
      value={{
        footerBlogData,
        fetchBlogList,
        fetchUserIPv4,
        getDeviceInfo,
        ipAddress,
        deviceInfo,
        bottomSliderBlogs
      }}>
      {children}
    </DataContext.Provider>
  );
};
