import React, { useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { API_URL, CONSTANT } from '../../Utils/Constant';
import Spinner from '../Loader/Spinner';
import './ContactForm.scss';
import { toast } from 'react-toastify';
const ContactForm = () => {
  const [userInput, setUserInput] = useState({
    name: '',
    email: '',
    mobile: '',
    description: ''
  });
  const [error, setError] = useState({
    errorName: '',
    errorEmail: '',
    errorPhone: ''
  });
  const [success, setSuccess] = useState('');
  const [loader, setLoader] = useState(false);

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setUserInput((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const phoneChangeHandler = (value) => {
    setUserInput((prev) => {
      return { ...prev, mobile: value };
    });
  };

  const submitFormHandler = (event) => {
    event.preventDefault();
    setError({ errorName: '', errorEmail: '', errorPhone: '' });
    let hasError = false;
    setLoader(true);
    setError({ errorName: '', errorEmail: '' });
    if (userInput.name.trim() == 0 && userInput.email.trim() == 0) {
      setError({
        errorName: 'Please enter your name',
        errorEmail: 'Please enter your email'
      });
      setLoader(false);
      hasError = true;
    } else if (userInput.name.trim() == 0) {
      setError({ errorName: 'Please enter your name' });
      setLoader(false);
      hasError = true;
    } else if (userInput.email.trim() == 0) {
      setError({ errorEmail: 'Please enter your email' });
      setLoader(false);
      hasError = true;
    } else if (userInput.mobile !== '') {
      if (!isValidPhoneNumber(userInput.mobile)) {
        setError({ errorPhone: 'Invalid phone number' });
        setLoader(false);
        hasError = true;
      }
    } else if (!isValidEmail(userInput.email)) {
      setError({ errorEmail: 'Invalid email format' });
      setLoader(false);
      hasError = true;
    }
    if (hasError) {
      return;
    }

    try {
      fetch(`${CONSTANT.BACKEND_API_URL}${API_URL.CONTACTS}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data: userInput })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(() => {
          setUserInput({
            name: '',
            email: '',
            mobile: '',
            description: ''
          });
          setLoader(false);
          toast.success("Thank you for reaching out! We'll get back to you soon.");
          setSuccess('Thank you for reaching out. ');

          document.querySelector('#form-submit-event-track').click();
        })
        .catch((error) => {
          setLoader(false);
          console.error('There was a problem with the fetch operation:', error.message);
          setSuccess('Something went wrong');
          setTimeout(() => setSuccess(''), 4000);
        });
    } catch (error) {
      setLoader(false);
      console.error('Caught error outside fetch block:', error);
      setSuccess('Something went wrong');
      setTimeout(() => setSuccess(''), 4000);
    }
  };
  const submitFormGoogleTagHandler = () => {
    window.gtag_report_conversion();
  };
  const isValidEmail = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };

  return (
    <div className="contact-form" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
      <p>
        Let&apos;s hear what you are looking for and we&apos;ll help you find the best solution!
      </p>
      <div className="form">
        <form onSubmit={submitFormHandler}>
          <div className="form-input">
            <input
              className={`input ${error?.errorName && 'error-name'}`}
              type="text"
              name="name"
              placeholder="Name"
              value={userInput?.name}
              onChange={(e) => changeHandler(e)}
            />
          </div>
          <span className="error">{error?.errorName}</span>
          <div className="form-input">
            <input
              className={`input ${error?.errorEmail && 'error-email'}`}
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={userInput?.email}
              onChange={(e) => changeHandler(e)}
            />
          </div>
          <span className="error">{error?.errorEmail}</span>
          <div className="phone-container">
            <PhoneInput
              className="phone"
              international
              defaultCountry="US"
              name="mobile"
              value={userInput?.mobile}
              onChange={phoneChangeHandler}
              limitMaxLength={true}
            />
          </div>
          <span className="error">{error?.errorPhone}</span>
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="10"
            maxLength="1000"
            placeholder="Description"
            value={userInput?.description}
            onChange={(e) => changeHandler(e)}
          />
          <button
            type="submit"
            className={`primary-btn ${loader && 'non-hover'}`}
            disabled={loader && true}>
            {!loader ? (
              <span>Get Started</span>
            ) : (
              <div className="loader">
                <Spinner />
              </div>
            )}
          </button>
          <button
            type="button"
            id="form-submit-event-track"
            onClick={submitFormGoogleTagHandler}
            className="btn-text">
            {success}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
