import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import HeroText from '../../Components/HeroText/HeroText';
import BlogList from '../../Components/BlogList/BlogList';
import { SEO } from '../../Utils/Constant';

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>{SEO.BLOG_PAGE_TITLE}</title>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <HeroText
        title={'What’s Next'}
        content={
          'Explore insightful articles, expert opinions, and industry trends in our dynamic blog section. Stay informed, inspired, and ahead of the curve with our carefully curated content.'
        }
      />
      <BlogList />
      <Footer />
    </>
  );
};

export default Blog;
