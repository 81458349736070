export const CONSTANT = {
  PIXERFECT_SOLUTIONS_LLP: 'Pixerfect Solutions LLP',
  PIXERFECT_WEB_LINK: 'https://www.pixerfect.com',
  PIXERFECT_MAIL: 'info@pixerfect.com',
  PIXERFECT_MOBILE_NUMBER: '+91 63556 23396',
  BACKEND_API_URL: 'https://www.api.pixerfect.com',
  // BACKEND_API_URL: 'http://localhost:1337',
  MAP_API_KEY: 'AIzaSyB45deDJBtBnee8CnPE0IyRVpygnfjRLrM'
};

export const API_URL = {
  BLOG_CATEGORIES: '/api/blog-categories',
  BLOGS: '/api/blogs?populate=*',
  CONTACTS: '/api/contacts',
  CAREERS: '/api/carriers',
  NEWS_LETTER: '/api/news-letters'
};

export const SEO = {
  HOME_PAGE_TITLE: `Leading Website & Mobile App Development Company | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  PROJECTS_PAGE_TITLE: `Case Studies | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  SERVICES_PAGE_TITLE: `Services | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  CAREER_PAGE_TITLE: `Career | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  OUR_TEAM_PAGE_TITLE: `Our Team | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  ABOUT_US_PAGE_TITLE: `About Us | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  TESTIMONIAL_PAGE_TITLE: `Testimonial | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  NOT_FOUND_PAGE_TITLE: `Not Found | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  BLOG_PAGE_TITLE: `Blog | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  BLOG_DETAILS_PAGE_TITLE: `Blog Details | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  TOS_PAGE_TITLE: `Terms Of Service | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  PRIVACY_POLICY_PAGE_TITLE: `Privacy Policy | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  HOME_PAGE_DESCRIPTION: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} is a top well-known software development company in the Surat, India. 300+ Successful projects 150+ Global clients Your Trusted Partner for Custom Web & Mobile application development`,
  PROJECTS_PAGE_DESCRIPTION: `Check our notable projects and custom applications developed by ${CONSTANT.PIXERFECT_SOLUTIONS_LLP} over the years. This projects is our wall of pride.`,
  SERVICES_PAGE_DESCRIPTION: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} is the best IT Company in Surat, India for Software, Web Development Services, Mobile App, Game Development Services, AI Development Services, Digital Marketing & Cloud and Aws Services.`,
  CAREER_PAGE_DESCRIPTION: `Looking for professional developers to work on your project? Take help of ${CONSTANT.PIXERFECT_SOLUTIONS_LLP} and hire developers for your project.`,
  OUR_TEAM_PAGE_DESCRIPTION: `If you are in search of experienced software developers, coders, testers, etc. resources that work as dedicated individuals or teams then we are the perfect find`,
  ABOUT_US_PAGE_DESCRIPTION: `${CONSTANT.PIXERFECT_SOLUTIONS_LLP} is one of the leading tech and web development companies. But how did we manage to reach this stage? Read about it in our story.`,
  TESTIMONIAL_PAGE_DESCRIPTION: `Testimonials from custom web design, web development, app development, game development, digital marketing, UI-UX, AI development and CLOUD & AWS clients.`,
  HOME_PAGE_KEYWORDS:
    'Pixerfect, Software Development, Website, Application, Game, Digital marketing, Design, UI UX, AI, CLOUD & AWS, custom software development, application development',
  PROJECT_PAGE_KEYWORDS:
    'IT services,Managed IT services,Cloud computing,Network security,Cybersecurity solutions,Data backup and recovery,IT consulting,Software development,Web development,Mobile app development,Network infrastructure,IT support services,Server management,Enterprise solutions,IT outsourcing,Technology solutions, Customer Relationship Management, E-Commerce Web Application,Android application',
  TESTIMONIAL_PAGE_KEYWORDS: `Customer testimonials,Client feedback,User reviews,Testimonial quotes,Customer satisfaction,Client testimonials,Happy customers,Positive feedback,Client success stories,User experiences,Testimonial page,Customer reviews,Client comments,Client testimonials,User testimonials`,
  ABOUT_US_PAGE_KEYWORDS: `Pixerfect Solutions LLP history,Our mission,Vision statement,Core values,Team members,Pixerfect solution culture,Leadership team,Corporate responsibility,Pixerfect solution achievements,Business philosophy,Pixerfect solution overview,About Pixerfect solution,Our story,Pixerfect solution founders,Corporate ethos,Pixerfect solution profile,What we do,Why choose pixerfect,Meet the team`,

  //Contact-Us
  CONTACT_PAGE_TITLE: `Contact Us | ${CONSTANT.PIXERFECT_SOLUTIONS_LLP}`,
  CONTACT_PAGE_DESCRIPTION: `Contact Us ${CONSTANT.PIXERFECT_SOLUTIONS_LLP} for custom web & mobile application development services. Get in touch with us for your software solutions.`,
  CONTACT_PAGE_KEYWORDS: `Contact Us ${CONSTANT.PIXERFECT_SOLUTIONS_LLP},Contact us,Software development company contact,Surat software company,Web development services,Mobile app development services,Software solutions,Custom application development,Contact information,Get in touch`

  //Services
};
