import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ServiceDetailsRoundShape from '../../Assets/Images/service-details-round-shape.png';
import VectorShapeUi from '../../Assets/Images/vector-shape-ui.png';
import { CONSTANT } from '../../Utils/Constant';
import './ServiceDetailsHero.scss';
import { Helmet } from 'react-helmet';

const ServiceDetailsHero = ({ servicesData }) => {
  let { serviceName } = useParams();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (animate) {
      setAnimate(false);
      setTimeout(() => {
        setAnimate(true);
      }, 100);
    } else {
      setAnimate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceName]);

  const ServiceInfo = () => {
    return (
      <div className="content-text">
        <h4 data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
          {servicesData?.descriptionTitle}
        </h4>
        <p data-aos="fade-up" data-aos-duration="1100" data-aos-once="true">
          {servicesData?.descriptionContent}
        </p>
        {servicesData?.keyword && servicesData?.keyword?.length > 0 && (
          <ul>
            {servicesData?.keyword?.map((item, index) => (
              <li
                key={index}
                data-aos="fade-up"
                data-aos-duration={'1' + (index + 2) + '00'}
                data-aos-once="true">
                <span>{item}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>{servicesData?.title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={servicesData?.howItWorksContent} />
        <meta name="keywords" content={servicesData?.keyword?.map((x) => x).join(',')} />

        <meta property="og:title" content={servicesData?.title} />
        <meta property="og:description" content={servicesData?.howItWorksContent} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.PIXERFECT_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.PIXERFECT_SOLUTIONS_LLP} />
        <meta property="og:image" content="https://www.pixerfect.com/Group_192_192.png" />
        <meta property="twitter:image" content="https://www.pixerfect.com/Group_192_192.png" />

        <meta name="twitter:title" content={servicesData?.title} />
        <meta name="twitter:description" content={servicesData?.howItWorksContent} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="container">
        <div className="service-details-info">
          <div className="service-details-wrap">
            <div className="service-details-content">
              <ServiceInfo />
              <div className="mobile-service-details-title">
                <h3>{servicesData?.title}</h3>
              </div>
              <div className="vector-shape-ui">
                <img
                  src={VectorShapeUi}
                  alt={servicesData?.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  title={servicesData?.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                />
              </div>
            </div>
            <div className="service-details-right">
              <div className="service-details-title">
                <h3>{servicesData?.title}</h3>
              </div>
              <div className="round-shape-mock">
                <div className="round-shape-wrap">
                  <div className={`round-shape-ui ${animate ? 'active' : ''}`}>
                    <img
                      src={ServiceDetailsRoundShape}
                      alt={servicesData?.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                      title={servicesData?.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                    />
                  </div>
                  <div className={`service-mockup ${animate ? 'active' : ''}`}>
                    <img
                      src={servicesData?.imgUrl}
                      alt={servicesData?.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                      title={servicesData?.title + ' | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-view">
            <ServiceInfo />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetailsHero;
