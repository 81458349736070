import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { CONSTANT, SEO } from '../../Utils/Constant';
import Contact from '../../Components/Contact/Contact';
import HeroText from '../../Components/HeroText/HeroText';
import CareerContact from '../../Components/CareerContact/CareerContact';
import './OurTeam.scss';
import EventsAndCelebrations from '../../Components/EventsAndCelebrations/EventsAndCelebrations';
import OurTeamHero from '../../Components/OurTeamHero/OurTeamHero';

const OurTeam = () => {
  return (
    <>
      <Helmet>
        <title>{SEO.OUR_TEAM_PAGE_TITLE}</title>
        <meta name="description" content={SEO.OUR_TEAM_PAGE_DESCRIPTION} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={SEO.OUR_TEAM_PAGE_TITLE} />
        <meta property="og:description" content={SEO.OUR_TEAM_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.PIXERFECT_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.PIXERFECT_SOLUTIONS_LLP} />
        <meta property="og:image" content="https://www.pixerfect.com/Group_192_192.png" />
        <meta property="twitter:image" content="https://www.pixerfect.com/Group_192_192.png" />
        <meta name="twitter:title" content={SEO.OUR_TEAM_PAGE_TITLE} />
        <meta name="twitter:description" content={SEO.OUR_TEAM_PAGE_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <HeroText
        title={'Meet Our Pixel Perfectos'}
        content={
          'Our team is composed of gifted, committed professionals who bring a host of skills and talents to our company. Together, they have assisted plethora of companies in expanding and growing their businesses.'
        }
      />
      <OurTeamHero />
      <EventsAndCelebrations />
      <Contact />
      <CareerContact />
      <Footer />
    </>
  );
};

export default OurTeam;
