import React from 'react';
import { Helmet } from 'react-helmet';
import { CONSTANT, SEO } from '../../Utils/Constant';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Contact from '../../Components/Contact/Contact';
import HeroText from '../../Components/HeroText/HeroText';
import OpenPosition from '../../Components/OpenPosition/OpenPosition';
import CareerContact from '../../Components/CareerContact/CareerContact';
import RecruitmentProcess from '../../Components/RecruitmentProcess/RecruitmentProcess';
import './Career.scss';

const Career = () => {
  return (
    <>
      <Helmet>
        <title>{SEO.CAREER_PAGE_TITLE}</title>
        <meta name="description" content={SEO.CAREER_PAGE_DESCRIPTION} />
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={SEO.CAREER_PAGE_TITLE} />
        <meta property="og:description" content={SEO.CAREER_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.PIXERFECT_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.PIXERFECT_SOLUTIONS_LLP} />
        <meta property="og:image" content="https://www.pixerfect.com/Group_192_192.png" />
        <meta property="twitter:image" content="https://www.pixerfect.com/Group_192_192.png" />
        <meta name="twitter:title" content={SEO.CAREER_PAGE_TITLE} />
        <meta name="twitter:description" content={SEO.CAREER_PAGE_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <>
        <Header />
        <HeroText
          title={'Shape Your Career In The Right Direction'}
          content={
            "At Pixerfect, we want to support you and your journey to create a meaningful and impactful career. Join our team to help solve business owners' most meaningful problems."
          }
        />
        <OpenPosition />
        <RecruitmentProcess />
        <Contact />
        <CareerContact />
        <Footer />
      </>
    </>
  );
};

export default Career;
