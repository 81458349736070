import React, { Suspense, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import RectangleImg from '../../Assets/Images/rectangle-background-shape.png';
import RightArrow from '../../Assets/Svg/RightArrow';
import { API_URL, CONSTANT } from '../../Utils/Constant';
import Spinner from '../Loader/Spinner';
import './Blogs.scss';

const Blogs = () => {
  const baseUrl = CONSTANT.BACKEND_API_URL;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    arrows: false
  };
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    fetchBlogList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBlogList = async () => {
    try {
      const url = `${baseUrl}${API_URL.BLOGS}&sort=updatedAt:desc`;
      const response = await fetch(url).then((response) => response.json());
      if (response.data && response.data?.length > 0) {
        setBlogData(response.data?.slice(0, 3));
      } else {
        console.log('Blog List Not Found!');
      }
    } catch (error) {
      console.log('ERROR : Blog List Not Found!');
    }
  };

  return (
    <div className="blogs">
      <div className="container">
        <div className="blog-container">
          <div className="leftpart">
            <div className="slider-container">
              <Suspense fallback={<Spinner />}>
                <Slider className="blog" {...settings}>
                  {blogData.map((item, idx) => {
                    item = item?.attributes;
                    return (
                      <div key={idx} className="blog-content">
                        <div className="blog-img">
                          <img
                            src={baseUrl + item?.bannerImage?.data?.attributes?.url}
                            alt={item?.title}
                            title={item?.title}
                          />
                        </div>
                        <div className="shadow"></div>
                        <div className="blog-description">
                          <Link
                            to={`/blog/${item?.slug}`}
                            className="blog-title-link"
                            onClick={() => window.scrollTo(0, 0)}>
                            <h5 data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                              {item?.title}
                            </h5>
                          </Link>
                          <p data-aos="fade-up" data-aos-duration="1100" data-aos-once="true">
                            <span className="large-screen">
                              {item?.shortDescription?.length > 180
                                ? `${item?.shortDescription.slice(0, 180)}...`
                                : item?.shortDescription}
                            </span>
                            <span className="mobile-screen">
                              {item?.shortDescription?.length > 110
                                ? `${item?.shortDescription.slice(0, 110)}...`
                                : item?.shortDescription}
                            </span>
                            <Link
                              to={`/blog/${item?.slug}`}
                              className="arrow-btn"
                              onClick={() => window.scrollTo(0, 0)}>
                              <span>Learn more</span>
                              <RightArrow className="right-arrow" />
                            </Link>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </Suspense>
            </div>
          </div>
          <div className="rightpart">
            <div className="right-part-content">
              <h4 data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                Blogs
              </h4>
              <p data-aos="fade-up" data-aos-duration="1100" data-aos-once="true">
                Dive into a world of insightful articles, expert opinions, and industry updates.
              </p>
            </div>
          </div>
          <div className="img-corner">
            <img
              src={RectangleImg}
              alt={CONSTANT.PIXERFECT_SOLUTIONS_LLP}
              title={CONSTANT.PIXERFECT_SOLUTIONS_LLP}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
