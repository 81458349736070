import React from 'react';
import { Helmet } from 'react-helmet';
import Testimonial from './Testimonial';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { CONSTANT, SEO } from '../../Utils/Constant';
import Contact from '../../Components/Contact/Contact';
import HeroText from '../../Components/HeroText/HeroText';
import CareerContact from '../../Components/CareerContact/CareerContact';

const TestimonialPage = () => {
  return (
    <>
      <Helmet>
        <title>{SEO.TESTIMONIAL_PAGE_TITLE}</title>
        <meta name="description" content={SEO.TESTIMONIAL_PAGE_DESCRIPTION} />
        <meta name="keywords" content={SEO.TESTIMONIAL_PAGE_KEYWORDS} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />{' '}
        <meta property="og:title" content={SEO.TESTIMONIAL_PAGE_TITLE} />
        <meta property="og:description" content={SEO.TESTIMONIAL_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.PIXERFECT_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.PIXERFECT_SOLUTIONS_LLP} />
        <meta property="og:image" content="https://www.pixerfect.com/Group_192_192.png" />
        <meta property="twitter:image" content="https://www.pixerfect.com/Group_192_192.png" />
        <meta name="twitter:title" content={SEO.TESTIMONIAL_PAGE_TITLE} />
        <meta name="twitter:description" content={SEO.TESTIMONIAL_PAGE_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <HeroText
        title={'Hear From Our Clients'}
        content={
          "Our Personalized Tools Transform Businesses And Empower Individuals To Reach Their Highest Potential - Here's What A Few Of Them Have To Say."
        }
      />
      <Testimonial />
      <Contact />
      <CareerContact />
      <Footer />
    </>
  );
};

export default TestimonialPage;
