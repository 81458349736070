import React from 'react';
import './PrivacyAndService.scss';
import { IoMail } from 'react-icons/io5';
import { CONSTANT, SEO } from '../../Utils/Constant';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>{SEO.PRIVACY_POLICY_PAGE_TITLE}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div>
        <div
          className="privacy-service"
          data-aos="fade-up"
          data-aos-duration="1100"
          data-aos-once="true">
          <div className="container">
            <h2>Privacy Policy</h2>
            <p>
              At Pixerfect Solutions LLP, we value your privacy and are committed to protecting your
              personal information. This Privacy Policy outlines how we collect, use, disclose, and
              manage your data when you use our services, including but not limited to login,
              signup, account management, transactions, and credit usage. By using our services, you
              consent to the practices described in this Privacy Policy.
              <span>
                This is applicable to our all sub company like{' '}
                <a
                  href="https://assistree.com/"
                  title={'Assistree | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  className="remove-a-tag-css"
                  target="_blank"
                  rel="noreferrer">
                  Assistree.com
                </a>
              </span>
            </p>
            <div className="privacy">
              <div>
                <h3>Information We Collect</h3>
                <h4>Personal Information:</h4>
                <p>
                  When you create an account or use our services, we may collect personal
                  information, including but not limited to your name, email address, and payment
                  information.
                </p>
                <h4>Transaction Data:</h4>
                <p>
                  We collect data related to your transactions, including payment history,
                  subscription details, and transaction IDs.
                </p>
                <h4>Credit Usage:</h4>
                <p>
                  Information regarding your credit usage and balance is stored securely for billing
                  and service provision purposes.
                </p>
                <h4>Device and Usage Information:</h4>
                <p>
                  We may collect information about your device, browser, and usage patterns, such as
                  IP address, browser type, pages visited, and actions taken on our website.
                </p>
              </div>
              <div>
                <h3>How We Use Your Information</h3>
                <h4>Service Provision:</h4>
                <p>
                  We use your personal information to provide you with access to our services,
                  manage your account, and process transactions.
                </p>
                <h4>Communication:</h4>
                <p>
                  We may use your contact information to communicate with you about account-related
                  matters, service updates, and promotional offers.
                </p>
                <h4>Billing:</h4>
                <p>
                  Your payment information is used to process payments for our services and to
                  maintain accurate billing records.
                </p>
                <h4>Analytics:</h4>
                <p>
                  We analyze user data to improve our services, enhance user experience, and
                  optimize our website.
                </p>
              </div>
              <div>
                <h3>Data Security</h3>
                <p>
                  We employ industry-standard security measures to protect your personal information
                  from unauthorized access, disclosure, alteration, or destruction. Our payment
                  processing partner, Stripe, complies with Payment Card Industry Data Security
                  Standard (PCI DSS) requirements.
                </p>
                <h3>Data Sharing</h3>
                <p>
                  We do not sell, trade, or rent your personal information to third parties.
                  However, we may share your data with trusted partners and service providers who
                  assist us in delivering our services, provided they agree to protect your data in
                  accordance with this Privacy Policy.
                </p>
                <h3>Cookies and Tracking Technologies</h3>
                <p>
                  We use cookies and similar tracking technologies to enhance your browsing
                  experience, analyze website usage, and personalize content. You can manage your
                  cookie preferences through your browser settings.
                </p>
                <h3>Data Retention</h3>
                <p>
                  We retain your personal information for as long as necessary to fulfill the
                  purposes outlined in this Privacy Policy or as required by applicable laws. After
                  termination of your account, we will securely delete or anonymize your data as
                  appropriate.
                </p>
                <h3>Your Rights</h3>
                <p>
                  You have the right to access, correct, or delete your personal information. To
                  exercise these rights or request more information about your data, please contact
                  us.
                </p>
                <h3>Changes to Privacy Policy</h3>
                <p>
                  We may update this Privacy Policy as our services evolve or to comply with legal
                  requirements. We will post any changes on our website, and your continued use of
                  our services after such changes constitute acceptance of the updated policy.
                </p>
                <h3>Contact Us</h3>
                <p>
                  If you have questions or concerns about our Privacy Policy, please contact us at{' '}
                  <span className="link">
                    <IoMail />
                    <a
                      href={`https://mail.google.com/mail/?view=cm&fs=1&to=${CONSTANT.PIXERFECT_MAIL}`}
                      title={'Email | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                      target="_blank"
                      rel="noreferrer">
                      {CONSTANT.PIXERFECT_MAIL}
                    </a>
                    .
                  </span>
                </p>
                <p className="space">
                  By using our services, you acknowledge that you have read, understood, and agree
                  to the practices described in this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
