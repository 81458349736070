import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import HeroText from '../../Components/HeroText/HeroText';
import { API_URL, CONSTANT } from '../../Utils/Constant';
import BlogDetailSec from '../../Components/BlogDetailSec/BlogDetailSec';
import Contact from '../../Components/Contact/Contact';
import CareerContact from '../../Components/CareerContact/CareerContact';

const BlogDetails = () => {
  const params = useParams();
  const baseUrl = CONSTANT?.BACKEND_API_URL;
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    fetchBlogList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const fetchBlogList = async () => {
    try {
      const queryParams = `&filters[slug][$eq]=${encodeURIComponent(params?.blogName)}`;
      const url = `${baseUrl}${API_URL?.BLOGS}${queryParams}`;
      const response = await fetch(url)?.then((response) => response?.json());
      if (response?.data && response?.data?.length > 0) {
        setBlogData(response?.data[0]);
      }
    } catch (error) {
      console?.error('ERROR : Blog List Not Found!');
    }
  };

  return (
    <>
      {blogData && blogData?.attributes && blogData?.attributes?.title && (
        <Helmet>
          <title>{blogData?.attributes?.title}</title>
          <meta name="description" content={blogData?.attributes?.shortDescription} />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href={window.location.href} />
          <meta property="og:title" content={blogData?.attributes?.title} />
          <meta property="og:description" content={blogData?.attributes?.shortDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={CONSTANT?.PIXERFECT_WEB_LINK} />
          <meta property="og:site_name" content={CONSTANT?.PIXERFECT_SOLUTIONS_LLP} />
          <meta property="og:image" content="https://www.pixerfect.com/Group_192_192.png" />
          <meta property="twitter:image" content="https://www.pixerfect.com/Group_192_192.png" />
          <meta name="twitter:title" content={blogData?.attributes?.title} />
          <meta name="twitter:description" content={blogData?.attributes?.shortDescription} />
          <meta name="twitter:card" content="summary_large_image" />
          <script type="application/ld+json" data-react-helmet="true">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Article',
              headline: blogData?.attributes?.title,
              image: baseUrl + blogData?.attributes?.bannerImage?.data?.attributes?.url,
              author: {
                '@type': 'Organization',
                name: blogData?.attributes?.bloggerName
              },
              publisher: {
                '@type': 'Organization',
                name: CONSTANT?.PIXERFECT_SOLUTIONS_LLP,
                logo: {
                  '@type': 'ImageObject',
                  url: baseUrl + blogData?.attributes?.bloggerImage?.data?.attributes?.url
                }
              },
              isPartOf: { '@id': CONSTANT?.PIXERFECT_WEB_LINK },
              datePublished: blogData?.attributes?.createdAt,
              dateModified: blogData?.attributes?.updatedAt
            })}
          </script>
        </Helmet>
      )}
      <Header />
      {blogData && blogData?.attributes && blogData?.attributes?.title && (
        <>
          <HeroText
            title={blogData?.attributes?.title}
            content={blogData?.attributes?.shortDescription}
            blogAsset={baseUrl + blogData?.attributes?.bannerImage?.data?.attributes?.url}
            blogAssetAlt={blogData?.attributes?.title}
          />
          <BlogDetailSec blogData={blogData?.attributes} blogId={blogData?.id} />
        </>
      )}
      <Contact />
      <CareerContact />
      <Footer />
    </>
  );
};

export default BlogDetails;
