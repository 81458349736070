import React from 'react';
import './PrivacyAndService.scss';
import { IoCall, IoMail } from 'react-icons/io5';
import { CONSTANT, SEO } from '../../Utils/Constant';
import { Helmet } from 'react-helmet';

const TermsOfServices = () => {
  return (
    <>
      <Helmet>
        <title>{SEO.TOS_PAGE_TITLE}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div>
        <div
          className="privacy-service"
          data-aos="fade-up"
          data-aos-duration="1100"
          data-aos-once="true">
          <div className="container">
            <div>
              <h2>
                <a
                  href="https://assistree.com/"
                  title={'Assistree | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                  className="remove-a-tag-css"
                  target="_blank"
                  rel="noreferrer">
                  Assistree.com
                </a>{' '}
                Terms Of Service (Parent Company Pixerfect)
              </h2>
              <h3>Acceptance of Terms</h3>
              <p>
                Welcome to Pixerfect Solutions LLP. By accessing or using our website Assistree and
                the services provided therein, you agree to comply with and be bound by these Terms
                of Service. If you do not agree to these Terms, please refrain from using our
                website.
              </p>
              <h3>Description of Service</h3>
              <p>
                We have developed a Chrome extension designed to assist users in generating AI-based
                proposals for their clients, enabling them to create flawless, automatically
                generated proposals to enhance their business growth. Given that we provide AI
                services, there is a fee associated with our offering, and we offer various
                subscription plans through Stripe. These plans allow users to acquire additional
                credits for generating more AI proposals. Think of it as a prepaid model: when users
                deplete their credits to zero, they can make a payment to replenish their credits
                and continue utilizing the service.
              </p>
            </div>
            <div>
              <h3>Payment and Billing</h3>
              <h4>Payment Method:</h4>
              <p>
                We use Stripe as our payment gateway to process all payments. By using our services,
                you agree to comply with Stripe&apos;s Terms of Service.
              </p>
              <h4>Pricing:</h4>
              <p>
                The pricing for our services is clearly stated on our website Assistree.com and is
                subject to change at our discretion. Any changes to pricing will be communicated in
                advance.
              </p>
              <h4>Payment Plans:</h4>
              <p>
                We offer various payment plans, each with its own set of features and pricing. You
                may choose the plan that best suits your needs.
              </p>
            </div>
            <div>
              <h3>Refund and Cancellation Policy</h3>
              <h4>Cancellation:</h4>
              <p>
                You may cancel your subscription at any time. Once canceled, your subscription will
                not renew, and you will retain access to our services until the end of the current
                billing cycle.
              </p>
              <h4>Refunds:</h4>
              <p>
                We offer a 7-day money-back guarantee for new subscribers. If you are not satisfied
                with our services, you may request a refund within 7 days of your initial purchase.
                Full refund only will applicable if user not consumed the credits more then 20%. It
                should be equal to or less then 20% consumed for particular purchase.
              </p>
              <h4>Refund Process:</h4>
              <p>
                To request a refund, please contact our customer support team at{' '}
                <span className="link">
                  <IoMail />
                  <a
                    href={`https://mail.google.com/mail/?view=cm&fs=1&to=${CONSTANT.PIXERFECT_MAIL}`}
                    title={'Email | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                    target="_blank"
                    rel="noreferrer">
                    {CONSTANT.PIXERFECT_MAIL}
                  </a>{' '}
                  or <IoCall />
                  <a href="tel:+91 63556 23396">{CONSTANT.PIXERFECT_MOBILE_NUMBER}</a>.
                </span>{' '}
                Refunds will be processed in accordance with our refund policy.
              </p>
            </div>
            <div>
              <h3>User Conduct</h3>
              <p>
                By using our services, you agree not to engage in any prohibited activities,
                including but not limited to:
              </p>
              <div className="space">
                <p>Violating any applicable laws or regulations.</p>
                <p>Impersonating another person or entity.</p>
              </div>
              <h3>Privacy Policy</h3>
              <p>
                Our Privacy Policy governs the collection and use of user information. Please review
                our Privacy Policy to understand how we handle your data.
              </p>
              <h3>Termination</h3>
              <p>
                We reserve the right to terminate or suspend your access to our services at our
                discretion, without notice, for any violation of these Terms.
              </p>
              <h3>Changes to Terms</h3>
              <p>
                We may update these Terms from time to time. Any changes will be posted on our
                website, and it is your responsibility to review them periodically. Your continued
                use of our services after the posting of changes constitutes acceptance of those
                changes.
              </p>
              <h3>Contact Us</h3>
              <p>
                If you have questions or concerns about these Terms of Service, please contact us at{' '}
                <span className="link">
                  <IoMail />
                  <a
                    href={`https://mail.google.com/mail/?view=cm&fs=1&to=${CONSTANT.PIXERFECT_MAIL}`}
                    title={'Email | ' + CONSTANT.PIXERFECT_SOLUTIONS_LLP}
                    target="_blank"
                    rel="noreferrer">
                    {CONSTANT.PIXERFECT_MAIL}
                  </a>
                  .
                </span>
              </p>
              <p className="space">
                By using our services, you acknowledge that you have read, understood, and agree to
                be bound by these Terms of Service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfServices;
